import { useLayoutEffect } from 'react';

import config from '../config';
import { useInstance } from '../hooks/useInstance';
import { uncheckedIndexAccess_UNSAFE } from '../utils/uncheckedIndexAccess_UNSAFE';
import { getLogger } from './logger';

export function useLoggerConsoleCtrl(enabled = config.misc.consoleCtrl) {
  const api = useInstance(() => {
    const logger = getLogger();
    return {
      disableScope: (...scopes: string[]) => {
        for (const scope of scopes) {
          logger.disableScope(scope);
        }
      },
      enableScope: (...scopes: string[]) => {
        for (const scope of scopes) {
          logger.enableScope(scope);
        }
      },
      enabledScopes: logger.enabledScopes.bind(logger),
      verbose: logger.verbose.bind(logger),
      console: logger.console.bind(logger),
    };
  });
  useLayoutEffect(() => {
    if (!enabled) return;
    uncheckedIndexAccess_UNSAFE(window)['lp'] ||= {};
    uncheckedIndexAccess_UNSAFE(window)['lp']['log'] = api;
    return () => {
      if (typeof uncheckedIndexAccess_UNSAFE(window)['lp'] === 'object') {
        delete uncheckedIndexAccess_UNSAFE(window)['lp']['log'];
      }
    };
  }, [api, enabled]);
}
